import React, { useState }  from "react";
import axios from "axios";
import { graphql } from "gatsby"
import Layout from '../components/layout';
//import SEO from "../components/seo"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "contactPage"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
          }
        }
      }
    }
  }
}`

const ContactPage = (props) => {
    const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/467f98ea-592c-43ed-ad1a-0f95a6e48d0b",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    return (
        <Layout pageName = "contactPage">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-8 col-sm-12">
                        <div className="left-index left-index-stats">
                            <h2><strong>{data.title}</strong></h2>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="right-index">
                            <form onSubmit={handleOnSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="fname">Name *
                                            <input type="text" name="firstname" className="form-control rounded-0 input-field" id="fname" />
                                            <span>First Name</span>
                                        </label>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lname" id="lnamelabel">&nbsp;
                                            <input type="text" name="lastname" className="form-control rounded-0 input-field" id="lname" />
                                            <span>Last Name</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group mt-2 d-flex">
                                    <label htmlFor="email">Email Address *
                                        <input type="email" name="email" className="form-control rounded-0 input-field" id="email" />
                                    </label>
                                </div>
                                <div className="form-group mt-2 d-flex">
                                    <label htmlFor="subject">Subject *
                                        <input type="text" name="subject" className="form-control rounded-0 input-field" id="subject" />
                                    </label>
                                </div>
                                <div className="form-group mt-2 d-flex">
                                    <label htmlFor="message">Message *
                                        <textarea name="message" id="message" cols="30" rows="5" className="form-control rounded-0"></textarea>
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-contact rounded-0" disabled={serverState.submitting}>>SUBMIT</button>
                                {serverState.status && (
                                    <p className={!serverState.status.ok ? "errorMsg" : ""}>
                                        {serverState.status.msg}
                                    </p>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default ContactPage
